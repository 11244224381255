<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					ZT8351 Fiscal Product
				</div>
				<div class="sub-text">
					Security sealing & tamper-proof design
				</div>
			</div>
		</div>
		<div class="part-2">
			<h2>Large-capacity fiscal module inside Built-in hardware encryption module</h2>
            <h2>Support multiple encryption algorithms</h2>
		</div>
		<div class="part-3 part-3-1">
			<div class="part-3-main">Large memory</div>
			<div class="part-3-sub">Built-in 128M fiscal module</div>
		</div>
        <div class="part-3 part-3-2">
			<div class="part-3-main">Multiple & Stable network</div>
			<div class="part-3-sub">WIFI + 2G/3G/4G and Ethernet, <br>supporti dual IP communication coexistence</div>
		</div>
		<ProdSpec :data="zt8351.data" />

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
import { zt8351 } from '@/views/products/spec/spec_en';
import ProdSpec from "@/components/ProdSpec.vue";
import PageNav from "@/components/PageNav.vue"
import PageBot from "@/components/PageBottom.vue"
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import {
	resetRem
} from '@/all_js/all';
import {
	clickEffect
} from "@/all_js/click";
export default {
	name: 'ZT8351',
	components: {
		PageBot,
		PageNav,
		ProdSpec
	},
	created() {
	},
	watch: {

	},

	mounted() {

	},
	data() {
		return {
			avtive_nav: "",
			screenWidth: null,
			list_status: true,
			avtive_img: 0,
			zt8351
		}
	},
	methods: {
	}
}
</script>
<style scoped lang="less">
* {
	margin: 0;
	padding: 0;
	// transition: all 1s; // 设置动画效果
}

li {
	list-style-type: none;
}

.page_1 {
	width: 100%;
	height: 100vh;
	max-height: 950px;
	object-fit: cover; // 保持图片缩放时候不变形
	background-image: url("../../assets/images/prods/zt8351/bg1.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; // 子元素无法被选中

	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #fff;

		.cen-text {
			margin-top: 30vh;
			margin-left: 15%;
			// background-color: aqua;
			font-size: 60px;
			font-weight: 600;
		}

		.sub-text {
			margin-left: 15%;
			margin-top: 1vh;
			font-size: 28px;
		}
	}
}

.part-2 {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
    background-color: #111;
    
    h2 {
        color: #fff;
        font-weight: 400;
        line-height: 1.5;
    }
}

.part-3 {
	height: 90vh;
	background-size: 100% 100%;
	position: relative;

	div {
		color: #fff;
	}

	.part-3-main {
		font-size: 55px;
		position: absolute;
		left: 60%;
		top: 35%;
	}

	.part-3-sub {
		font-size: 24px;
		position: absolute;
		left: 60%;
		top: 48%;
	}
}

.part-3-1 {
    background-image: url("../../assets/images/prods/zt8351/bg2.png");
}

.part-3-2 {
    background-image: url("../../assets/images/prods/zt8351/bg3.png");
    
    .part-3-main {
        left: 15%;
    }

    .part-3-sub {
        left: 15%;
    }
}

.back_up {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	background-color: #49b1f5;

	.icon_xiangshang {
		height: 30px;
		width: 30px;
		border-radius: 30px;
	}
}

.back_up:hover {
	background-color: #49b1f5;
}
</style>
<style scoped lang="less">
// @media only screen and (min-width:1371px) {}
</style>